.whole {
  margin: 5px;
}
.timelinecomponent {
  border: 1px solid #86868640;
  margin: 30px 0;
  border-radius: 8px;
  padding: 20px;
}
.morebutton {
  color: #172b4d;
  cursor: pointer;
}

.tcheader {
  display: flex;
  justify-content: flex-start;
}
.button {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}
.table-icon {
  /* background-image: url("/app/images/cells.svg"); */
  background-repeat: no-repeat;
  width: auto;
  height: 10px;
  display: block;
  margin: 8px 0 4px 3px;
  background-size: contain;
}
.components {
  margin: 5px;
}
.chart {
  flex: 1;
  justify-content: 'center';
  align-items: 'center';
  min-height: 300px;
  height: 300px;
}
.tables {
  display: flex;
}
.tablerow1 {
  flex: 2;
}
.tablerow2 {
  flex: 1;
}
