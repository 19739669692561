.whole {
  margin: 5px;
}
.search {
  display: flex;
  flex-direction: row;
}
.components {
  margin: 3px;
  margin-top: 25px;
  color: '#5E6C84';
  text-transform: 'capitalize';
}
.search {
  padding: 3px;
}
