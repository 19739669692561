.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120%;
  background-color: rgb(75, 75, 75);
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 1px 0;
  border-radius: 3px;
  box-shadow: 1px 1px 3px 1px grey;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: 1px;
  left: 112%;
}
.tooltippointer {
  visibility: hidden;
  border-top: 5px solid transparent;
  border-right: 8px solid rgb(75, 75, 75);
  border-bottom: 5px solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  z-index: 2;
  top: 8px;
  left: 102%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip:hover .tooltippointer {
  visibility: visible;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
