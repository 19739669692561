.Whole {
  display: flex;
  flex-direction: row;
  margin: 1px;
  flex-wrap: wrap;
  align-content: space-between;
  margin-top: 7px;
}
.paragraph {
  align-self: center;
  color: #5e6c84;
  text-transform: uppercase;
  font-size: 12px;
}
.dropdown {
  margin: 5px;
  min-width: 200px;
  max-width: 150px;
}
.components {
  margin: 5px;
  min-width: 50px;
  max-width: 150px;
}
.datecomponents {
  margin: 5px;
  min-width: 118px;
  max-width: 150px;
}
.visible {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}
.invisible {
  display: flex;
  flex-direction: row;
  display: none;
  flex: 1;
  padding-top: 5px;
}
