.whole {
  margin: 5px;
}
.components {
  margin: 3px;
  margin-top: 25px;
}
.search {
  padding: 3px;
}
